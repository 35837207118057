
// source: https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8

import { useRef } from "react";
import "../styles/fileuploader.module.css";

import { useTranslation } from 'react-i18next';

export const FileUploader = ({ handleFile }) => {
  const { t } = useTranslation();

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <div>
      <button className="button-upload" onClick={handleClick}>
          {t('pages.contact.bouton_choisir_fichier_a_televerser')}
      </button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </div>
  );
};

import ImageCarousel from './ImageCarousel';
import style from '../styles/projet_full_screen.module.css'
import TitreCouleur from './TitreCouleur';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
    titre: string,
    description: string,
    description_courte: string,
    adresse: string,
    images: string[],
    categorie: string,
    sous_categorie: string
}

function ProjetFullScreen(props: Props) {

    const { t } = useTranslation();

    return (
        <div className={style.container}>
            <TitreCouleur texte={props.titre} taille={3.2}/>

            <div className={style.carousel}>
                <ImageCarousel imagesArray={props.images} />
            </div>

            <div className={style.container_contenu}>
                <p className={style.sous_titre}>{props.description_courte}</p>
                <p className={style.contenu}>{props.description}</p>
                <small>{props.categorie}</small>
                <br/>
                <br/>
                <br/>
                <center>
                    <Link className='global_navigation_link' to={'/projets/' }>
                        <button className="global_button">
                            {t('pages.projet.bouton_retour')}
                        </button>
                    </Link>
                </center>
            </div>
        </div>
    );
}

export default ProjetFullScreen;

import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from '../../database';
import { useTranslation } from 'react-i18next';
import TitreCouleur from '../../components/TitreCouleur';
import { useAuthState } from 'react-firebase-hooks/auth';
import EcranChargement from '../../components/EcranChargement';
import { collection } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

import AdminProjetsGridItem from '../../components/admin/ProjetsGridItem';

function AdminProjets() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, auth_loading ] = useAuthState(auth);

    const [firestore_values, firestore_loading ] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );

    const [projets_values, projets_loading, projets_error ] = useCollectionDataOnce(
        collection(db, 'projets')
    );

    const [loginStatus, setLoginStatus] = useState(false);

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values]);

    if (auth_loading || firestore_loading || projets_loading) {
        return <EcranChargement />
    }

    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }

    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }

    if (projets_error) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{projets_error.name} - {projets_error.code}</h3>
                <p>{projets_error.message}</p>
            </div>
        )
    }

    return (
        <div>
            <TitreCouleur texte='Projets' taille={3.2} />

            <div className="global_grid">
                {
                    projets_values?.map(({ id, titre, description, description_courte, adresse, images, categorie, sous_categorie }: any) => {
                        return (
                            <AdminProjetsGridItem
                                id={id}
                                titre={titre}
                                description={description}
                                description_courte={description_courte}
                                adresse={adresse}
                                images={images}
                                categorie={categorie}
                                sous_categorie={sous_categorie} />
                        )
                    })}

                <div className='global_card' style={{ alignItems: 'center' }}>
                    <div>
                        <h2>Ajouter un nouveau projet</h2>

                        <Link className='global_navigation_link' to={'/admin/projets/nouveau_projet'}>
                            <button className="global_button">
                                + Ajouter
                            </button>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminProjets;

import styles from '../styles/image_carousel.module.css'
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface Props {
    imagesArray: string[]
}

function ImageCarousel(props: Props) {

    let ref = useRef<any>(null)

    let imageCourante: number = 0;

    function handleScrollToNextImage() {
        let imageListRef = ref.current?.childNodes

        // aller vers la prochaine image
        if (imageCourante >= 0 && imageCourante < imageListRef.length - 1) {
            let nextImage = imageListRef[++imageCourante]
            nextImage?.scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"})
        }

        // retourner au debut du caroussel
        else if (imageCourante === imageListRef.length - 1) {
            imageCourante = 0
            let previousImage = imageListRef[imageCourante]
            previousImage?.scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"})
        }
    }

    function handleScrollToPreviousImage() {
        let imageListRef = ref.current?.childNodes

        // aller vers l'image precedente
        if (imageCourante > 0 && imageCourante <= imageListRef.length) {
            let previousImage = imageListRef[--imageCourante]
            previousImage?.scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"})
        }

        // aller vers la fin du carousel
        else if (imageCourante === 0) {
            imageCourante = imageListRef.length - 1
            let previousImage = imageListRef[imageCourante]
            previousImage?.scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"})
        }
    }

    return (
        <div className={styles.carousel}>

            <div className={styles.carousel_slider} ref={ref}>
                {
                    props.imagesArray.map((url) => (
                        <div className={styles.carousel_slider_item} key={url}>
                            <img
                                className={styles.carousel_image}
                                src={url}>
                            </img>
                        </div>
                    ))
                }
            </div>
            <div className={styles.boutons}>
                <button onClick={() => handleScrollToPreviousImage()} className={styles.bouton} id={styles.back}>
                    <FontAwesomeIcon icon={faArrowLeft} /></button>
                <button onClick={() => handleScrollToNextImage()} className={styles.bouton} id={styles.next}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>
        </div>
    )
}

export default ImageCarousel;

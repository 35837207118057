import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from '../../database';
import TitreCouleur from '../../components/TitreCouleur';

import toast from 'react-hot-toast';
import { signInWithEmailAndPassword } from 'firebase/auth';

function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                toast.success('Vous êtes connecté.')
                navigate('/admin')
                // const user = userCredential.user;
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/user-not-found':
                        toast.error('Le compte spécifié n\'existe pas.')
                        break;
                    case 'auth/wrong-password':
                        toast.error('Le mot de passe est incorrect.')
                        break;
                    default:
                        toast.error('Une erreur est survenue: ' + error.name)
                        break;
                }
            });
    }

    return (
        <div>
            <TitreCouleur texte='Connexion administrateur' taille={3.2} />

            <div className='global_form center'>

                <label htmlFor="emplacement" className='global_form_label'>Courriel</label>
                <input
                    className='global_text_field'
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="emplacement" className='global_form_label'>Mot de passe</label>
                <input
                    className='global_text_field'
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className='global_button' onClick={() => login()}>
                    Connexion
                </button>
            </div>

        </div>
    );
}

export default Login;

import { useEffect, useState } from 'react';
import TitreCouleur from '../components/TitreCouleur';
import styles from '../styles/accueil.module.css'
import { Link } from 'react-router-dom';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { db } from '../database';
import EcranChargement from '../components/EcranChargement';
import { useTranslation } from 'react-i18next';

function Nouvelles() {
    const { t } = useTranslation();

    const [values, loading] = useCollectionDataOnce(
        collection(db, 'nouvelles')
    );

    const [nouvelles, setNouvelles] = useState<any[]>();

    useEffect(() => {
        if (values) {
            let sortedValues = values?.sort((a: any, b: any) => b.date_publication.seconds - a.date_publication.seconds)
            setNouvelles(sortedValues)
        }
    }, [values])

    return (
        <div>
            <TitreCouleur texte={t('pages.nouvelles.titre')} taille={3.2}/>

            {
                loading ? <EcranChargement /> : (
                    <div>
                        {
                            !nouvelles ? "Aucun contenu" : (
                                nouvelles.map(nouvelle => {
                                    return (
                                        <Link to={`/nouvelles/${nouvelle.id}`} style={{ textDecoration: 'none' }}>
                                            <div className={styles.nouvelles_preview_container} style={{ textDecoration: 'none', marginBottom: "20px" }}>
                                                <h2>{nouvelle.titre}</h2>
                                                <p>{nouvelle.sous_titre}</p>
                                            </div>
                                        </Link>
                                    )
                                })
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

export default Nouvelles;

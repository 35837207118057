import React from 'react';
import HeaderBar from './components/HeaderBar';
import Footer from './components/Footer';

interface Props {
    children: React.ReactNode
}

function Layout(props:Props) {
  return (
    <div>
        <HeaderBar />
        
        <div className='mainContent' style={{minHeight:'100vh'}}>
            {props.children}
        </div>

        <Footer />
    </div>
  );
}

export default Layout;

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { db, auth } from '../../database';
import { useTranslation } from 'react-i18next';
import TitreCouleur from '../../components/TitreCouleur';
import { useAuthState } from 'react-firebase-hooks/auth';
import EcranChargement from '../../components/EcranChargement';
import { collection, doc, updateDoc, addDoc } from 'firebase/firestore';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import toast from 'react-hot-toast';


function AdminModifierNouvelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, auth_loading] = useAuthState(auth);

    const [firestore_values, firestore_loading] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );

    const [nouvelles, nouvelles_loading, nouvelles_error] = useDocumentDataOnce(
        doc(db, 'nouvelles', id + '')
    );

    const [loginStatus, setLoginStatus] = useState(false);

    // valeurs pour le formulaire
    const [titre, setTitre] = useState('');
    const [sousTitre, setSousTitre] = useState('');
    const [contenu, setContenu] = useState('');
    const [datePublication, setDatePublication] = useState('');

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values]);

    useEffect(() => {
        setTitre(nouvelles?.titre);
        setSousTitre(nouvelles?.sous_titre);
        setContenu(nouvelles?.contenu);
        setDatePublication(nouvelles?.datePublication);
    }, [nouvelles]);


    if (auth_loading || firestore_loading || nouvelles_loading) {
        return <EcranChargement />
    }


    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }


    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }

    if (nouvelles_error) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{nouvelles_error.name} - {nouvelles_error.code}</h3>
                <p>{nouvelles_error.message}</p>
            </div>
        )
    }


    async function formSubmit() {

        if (!nouvelles) {
            await addDoc(collection(db, "nouvelles"), {
                titre: titre,
                sous_titre: sousTitre,
                contenu: contenu,
                date_publication: new Date()
            }).then((docRef) => {
                console.log(docRef);
                id = docRef.id;

                updateDoc(doc(db, "nouvelles", id + ''), {
                    id: id
                })
            }).then(() => {
                toast.success('Nouvelle créée.')
                // navigate('/admin/nouvelles')
            }).catch(() => {
                toast.error('Une erreur est survenue. Veuillez réessayer plus tard.')
            })
        } else {
            await updateDoc(doc(db, "nouvelles", id + ''), {
                nom: titre,
                contenu: contenu,
                sous_titre: sousTitre,
                date_publication: new Date()
            }).then(() => {
                toast.success('Informations enregistrées.')
                navigate('/admin/nouvelles')
            }).catch(() => {
                toast.error('Une erreur est survenue. Veuillez réessayer plus tard.')
            })
        }
    }

    return (
        <div>
            <TitreCouleur texte='Modifier/créer une nouvelle' taille={3.2} position='center' />

            <div className='global_form center'>

                <label htmlFor="titre" className='global_form_label'>Titre</label>
                <input
                    className='global_text_field'
                    type="text"
                    value={titre}
                    defaultValue={nouvelles?.titre}
                    onChange={(e) => setTitre(e.target.value)}
                />

                <label htmlFor="sous_titre" className='global_form_label'>Sous-titre</label>
                <input
                    className='global_text_field'
                    type="text"
                    value={sousTitre}
                    defaultValue={nouvelles?.sous_titre}
                    onChange={(e) => setSousTitre(e.target.value)} />

                <label htmlFor="contenu" className='global_form_label'>Contenu</label>
                <textarea
                    className='global_text_field global_text_area'
                    value={contenu}
                    defaultValue={nouvelles?.contenu}
                    onChange={(e) => setContenu(e.target.value)}
                />

                <button className='global_button' onClick={async () => await formSubmit()}>
                    Enregistrer
                </button>
            </div>
        </div>
    );
}

                // <label htmlFor="datePublication" className='global_form_label'>Date de publication</label>
                // <input
                //     className='global_text_field'
                //     type="text"
                //     value={datePublication}
                //     defaultValue={nouvelles?.date_publication}
                //     onChange={(e) => setDatePublication(e.target.value)} />
export default AdminModifierNouvelle;

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { db, auth } from '../../database';
import { useTranslation } from 'react-i18next';
import TitreCouleur from '../../components/TitreCouleur';
import { useAuthState } from 'react-firebase-hooks/auth';
import EcranChargement from '../../components/EcranChargement';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';

import toast from 'react-hot-toast';

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

function AdminModifierMembreEquipe() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, auth_loading ] = useAuthState(auth);

    const [firestore_values, firestore_loading ] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );

    const [employes_values, employes_loading, employes_error ] = useDocumentDataOnce(
        doc(db, 'employes', id + '')
    );

    const [loginStatus, setLoginStatus] = useState(false);

    // valeurs pour le formulaire
    const [nomData, setNomData] = useState('');
    const [descriptionData, setDescription] = useState('');
    const [roleData, setRoleData] = useState('');
    const [urlData, setUrlData] = useState('');

    const [selectedFile, setSelectedFile] = useState<File>();
    const storage = getStorage();

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values]);

    useEffect(() => {
        setNomData(employes_values?.nom);
        setDescription(employes_values?.description);
        setRoleData(employes_values?.role)
        setUrlData(employes_values?.url)
    }, [employes_values]);

    if (auth_loading || firestore_loading || employes_loading) {
        return <EcranChargement />
    }

    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }

    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }

    if (employes_error) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{employes_error.name} - {employes_error.code}</h3>
                <p>{employes_error.message}</p>
            </div>
        )
    }

    async function formSubmit() {

        await updateDoc(doc(db, "employes", id + ''), {
            nom: nomData,
            description: descriptionData,
            role: roleData,
            url: urlData
        }).then(() => {
            toast.success('Informations enregistrées.')
            navigate('/admin/equipe')
        }).catch(() => {
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard.')
        })

        let url = await uploadFichiers()
        await ajouterImagesAuProjet(url)
        navigate('/admin/equipe')
    }

    async function uploadFichiers(): Promise<string> {
        let url: string = '';

        if (selectedFile) {
            const titreImage = selectedFile.size + selectedFile.name;
            const imageRef = ref(storage, `/employes/${id}/${titreImage}`);

            const uploadPromise = uploadBytes(imageRef, selectedFile)
                .then((snapshot) => getDownloadURL(snapshot.ref))
                .then((downloadURL) => {
                    return downloadURL;
                });

            try {
                url = await uploadPromise;
            } catch (error) {
                toast.error('Une erreur est survenue. Veuillez réessayer plus tard: ' + error)
            }
        }

        return url;
    }

    async function ajouterImagesAuProjet(url: string) {

        updateDoc(doc(db, "employes", id + ''), {
            image_url: url

        }).then(() => {
            toast.success('Informations enregistrées.')

        }).catch((error) => {
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard: ' + error)
        })
    }

    return (
        <div>
            <TitreCouleur texte='Modifier un équipier' taille={3.2} />

            <div className='global_form center'>

                <label htmlFor="nom" className='global_form_label'>Nom</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={titre}
                    defaultValue={employes_values?.nom}
                    onChange={(e) => setNomData(e.target.value)}
                />

                <label htmlFor="description" className='global_form_label'>Description de la personne</label>
                <textarea
                    className='global_text_field global_text_area'
                    // value={description}
                    defaultValue={employes_values?.description}
                    onChange={(e) => setDescription(e.target.value)} />

                <label htmlFor="role" className='global_form_label'>Role/poste</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={titre}
                    defaultValue={employes_values?.role}
                    onChange={(e) => setRoleData(e.target.value)}
                />

                <label htmlFor="role" className='global_form_label'>Lien/URL (optionnel)</label>
                <input
                    className='global_text_field'
                    type="url"
                    pattern="http://.*"
                    // value={titre}
                    defaultValue={employes_values?.url}
                    onChange={(e) => setUrlData(e.target.value)}
                />

                <label htmlFor="role" className='global_form_label'>Photo / avatar</label>
                <input
                    type="file"
                    onChange={(e) => {
                        const file = e.target.files ? e.target.files[0] : undefined;
                        setSelectedFile(file);
                    }}
                />

                <button className='global_button' onClick={async () => await formSubmit()}>
                    Enregistrer
                </button>
            </div>
        </div>
    );
}

export default AdminModifierMembreEquipe;


export const _license_rbq = "Licence RBQ : 5721-2573-01";

// calcul de l'age de l'entreprise
// https://www.registreentreprises.gouv.qc.ca/RQAnonymeGR/GR/GR03/GR03A2_19A_PIU_RechEnt_PC/PageEtatRens.aspx?T1.JetonStatic=ee23f59a-28af-4cc0-ba5b-61b0ab2efa4e&T1.CodeService=S00436
export const _dateFondation = new Date('2016-01-20')
export const _anneesOperationMS = new Date().getTime() - _dateFondation.getTime()
export const _anneesOperation = Math.round(_anneesOperationMS / 60 / 60 / 24 / 365 / 1000)

// https://console.cloud.google.com/apis/credentials?project=delamain-ca
export const _google_maps_api = 'AIzaSyDUFHyb0T-JbGWUkPd_96-1USiFW55FwzA'

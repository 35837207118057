import styles from '../../styles/projets_grid_item.module.css'
import { Link } from 'react-router-dom';
import "../../styles/global.css"
import AdminBoutonSupprimer from './BoutonSupprimer';

interface Props {
    id: string,
    titre: string,
    description: string,
    description_courte: string,
    adresse: string,
    images: string[],
    categorie: string,
    sous_categorie: string
}

function AdminProjetsGridItem(props: Props) {

    return (
        <div className={styles.card}>
            <img className={styles.thumbnail} src={`${props.images[0]}`}></img>

            <div className={styles.texte_carte}>
                <h2 className={styles.titre}>{props.titre}</h2>
                <p className={styles.description}>{props.description_courte}</p>

                <Link className='global_navigation_link' to={'/admin/projets/' + props.id.trim()}>
                    <button className="global_button">
                        Modifier
                    </button>
                </Link>

                <br />
                <br />

                <AdminBoutonSupprimer
                    titre_item={props.titre}
                    collection_db={'projets'}
                    document_db={props.id.trim()}
                />
            </div>
        </div>
    );
}

export default AdminProjetsGridItem;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

function EcranChargement() {
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState(false);
    const [showLoadingTooLong, setshowLoadingTooLong] = useState(false);

    // attendre 500ms avant dafficher le message de chargement
    useEffect(() => {
        setTimeout(() => {
            setShowLoading(true);
        }, 500)
    }, []);

    // apres 3000ms afficher le message qui dit de recharger si cest trop long
    useEffect(() => {
        setTimeout(() => {
            setShowLoading(false);
            setshowLoadingTooLong(true);
        }, 3000)
    }, []);

    if (showLoading) {
        return (
            <div className='loading_page'>
                <CircularProgress />
            </div>
        )
    }

    if (showLoadingTooLong) {
        return (
            <div>
                <h1>{t('global.chargement')}</h1>
                <p>{t('global.chargement_erreur')}</p>
            </div>
        )
    }

    return <></>
}

export default EcranChargement;

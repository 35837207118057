import styles from '../styles/projets_grid_item.module.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


interface Props {
    id: string,
    titre: string,
    description: string,
    description_courte: string,
    adresse: string,
    images: string[],
    categorie: string,
    sous_categorie: string
}

function ProjetsGridItem(props: Props) {
    const { t } = useTranslation();

    return (
      <Link className='global_navigation_link' to={'/projets/' + props.id.trim()}>
        <div className={styles.card}>
            <img className={styles.thumbnail} src={`${props.images[0]}`}></img>

            <div className={styles.texte_carte}>
                <h2 className={styles.titre}>{props.titre}</h2>
                <p className={styles.description}>{props.description_courte}</p>

                    <button className='global_button'>
                        {t('global.voir_plus')}
                    </button>
            </div>
        </div>
      </Link>
    );
}

export default ProjetsGridItem;

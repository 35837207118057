// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCGBIce_yDboneCIfWkDfIcxk23plVX5Ws",
  authDomain: "delamain-ca-43f72.firebaseapp.com",
  projectId: "delamain-ca-43f72",
  storageBucket: "delamain-ca-43f72.appspot.com",
  messagingSenderId: "63414675268",
  appId: "1:63414675268:web:a40e09a9a98b063709d911"
};

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export const auth = getAuth(firebase);
export const storage = getStorage(firebase);

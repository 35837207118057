import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { db, auth } from '../../database';
import { useTranslation } from 'react-i18next';
import TitreCouleur from '../../components/TitreCouleur';
import { useAuthState } from 'react-firebase-hooks/auth';
import EcranChargement from '../../components/EcranChargement';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';

import toast from 'react-hot-toast';

function AdminModifierProjet() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, auth_loading ] = useAuthState(auth);

    const [firestore_values, firestore_loading ] = useCollectionDataOnce(
        collection(db, 'utilisateurs')
    );

    const [projets_values, projets_loading, projets_error ] = useDocumentDataOnce(
        doc(db, 'projets', id + '')
    );

    const [loginStatus, setLoginStatus] = useState(false);

    // valeurs pour le formulaire
    const [titreData, setTitre] = useState('');
    const [adresseData, setAdresse] = useState('');
    const [categorieData, setCategorie] = useState('');
    const [dateFinData, setDateFin] = useState('');
    const [descriptionData, setDescription] = useState('');
    const [descriptionCourteData, setDescriptionCourte] = useState('');

    // verifie si l'utilisateur est autorise dans la DB
    useEffect(() => {
        firestore_values?.forEach(element => {
            if (user?.uid === element.auth_userid) {
                setLoginStatus(true)
            }
        });
    }, [firestore_values]);

    useEffect(() => {
        setTitre(projets_values?.titre);
        setAdresse(projets_values?.adresse)
        setDescription(projets_values?.description);
        setDescriptionCourte(projets_values?.description_courte);
        setCategorie(projets_values?.categorie);
        setDateFin(projets_values?.date_fin);
    },[projets_values]);

    if (auth_loading || firestore_loading || projets_loading) {
        return <EcranChargement />
    }

    if (!auth_loading && !user) {
        navigate("/admin/login")
        return (
            <div>
                <h2>Vous devez être connecté pour voir ceci.</h2>
            </div>
        )
    }

    if (user && !firestore_loading && !loginStatus) {
        return (
            <div>
                <h2>Vous n'êtes pas autorisé à voir ceci.</h2>
            </div>
        )
    }

    if (projets_error) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>{t('global.erreur.erreur_bd.titre')}</h1>
                ---
                <h3>{projets_error.name} - {projets_error.code}</h3>
                <p>{projets_error.message}</p>
            </div>
        )
    }

    async function formSubmit() {

        await updateDoc(doc(db, "projets", id + ''), {
            titre: titreData,
            adresse: adresseData,
            description: descriptionData,
            description_courte: descriptionCourteData,
            date_fin: dateFinData,
            categorie: categorieData
        }).then(() => {
            toast.success('Informations enregistrées.')
            navigate('/admin/projets')
        }).catch(() => {
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard.')
        })
    }

    return (
        <div>
            <TitreCouleur texte='Modifier un projet' taille={3.2} />

            <div className='global_form center'>

                <label htmlFor="titre" className='global_form_label'>Titre</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={titre}
                    defaultValue={projets_values?.titre}
                    onChange={(e) => setTitre(e.target.value)}
                />

                <label htmlFor="adresse" className='global_form_label'>Adresse/emplacement</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={descriptionCourte}
                    defaultValue={projets_values?.adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                />

                <label htmlFor="descriptionCourte" className='global_form_label'>Description courte</label>
                <input
                    className='global_text_field'
                    type="text"
                    // value={descriptionCourte}
                    defaultValue={projets_values?.description_courte}
                    onChange={(e) => setDescriptionCourte(e.target.value)}
                />

                <label htmlFor="description" className='global_form_label'>Description longue</label>
                <textarea
                    className='global_text_field global_text_area'
                    // value={description}
                    defaultValue={projets_values?.description}
                    onChange={(e) => setDescription(e.target.value)} />

                <label htmlFor="dateFin" className='global_form_label'>Date de fin du projet</label>
                <input
                    className='global_text_field'
                    type="date"
                    // value={dateFin}
                    defaultValue={projets_values?.date_fin}
                    onChange={(e) => setDateFin(e.target.value)}
                />

                <label htmlFor="categorie" className='global_form_label'>Catégorie</label>
                <select
                    className='global_text_field'
                    id="categorie"
                    name='categorie'
                    // value={description}
                    defaultValue={projets_values?.categorie}
                    onChange={(e) => setCategorie(e.target.value)}>
                    <option value="Résidentiel">Résidentiel</option>
                    <option value="Commercial">Commercial</option>
                    <option value="Industriel">Industriel</option>
                </select>

                <button className='global_button' onClick={async () => await formSubmit()}>
                    Enregistrer
                </button>
            </div>
        </div>
    );
}

export default AdminModifierProjet;

import styles from '../../styles/projets_grid_item.module.css'
import { Link  } from 'react-router-dom';
import "../../styles/global.css"
import AdminBoutonSupprimer from './BoutonSupprimer';

interface Props {
    id: string,
    nom: string,
    description: string,
    image: string,
}

function AdminEmployesGridItem(props: Props) {

    return (
        <div className={styles.card}>
            <img className={styles.thumbnail} src={`${props.image}`}></img>

            <div className={styles.texte_carte}>
                <h2 className={styles.titre}>{props.nom}</h2>
                <p className={styles.description}>{props.description}</p>

                <Link className='global_navigation_link' to={'/admin/equipe/' + props.id.trim()}>
                    <button className="global_button">
                        Modifier
                    </button>
                </Link>

                <br />
                <br />

                <AdminBoutonSupprimer
                    titre_item={props.nom}
                    collection_db={'employes'}
                    document_db={props.id.trim()}
                />
            </div>
        </div>
    );
}

export default AdminEmployesGridItem;

import { db } from '../../database';
import { deleteDoc, doc } from 'firebase/firestore';
import "../../styles/global.css"
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

interface Props {
    titre_item: string,
    collection_db: string,
    document_db: string,
}

function AdminBoutonSupprimer(props: Props) {
    const navigate = useNavigate();

    async function deleteDocument() {

        let confirmationTitre = prompt(`Pour confirmer la suppression, veuillez taper '${props.titre_item}'. Le texte est sensible aux majuscules.`, "");

        if (confirmationTitre === props.titre_item) {
            let isConfirm = window.confirm(`Voulez vous vraiment supprimer '${props.titre_item}'? Cette action est irréversible.`);

            if (isConfirm) {
                await deleteDoc(doc(db, props.collection_db, props.document_db)
                ).then(() => {
                    toast.success('Suppression effectuée.')
                    navigate(0);
                }).catch(() => {
                    toast.error('Une erreur est survenue. Veuillez réessayer plus tard.')
                });
            }
        }
    }

    return (
        <div>
            <button
                className="global_button"
                style={{ background: 'linear-gradient(143deg, #ff0000 0%, #be0000 100%)', color: "white" }}
                onClick={() => deleteDocument()}>
                Supprimer
            </button>
        </div>
    );
}

export default AdminBoutonSupprimer;

import { useEffect } from 'react';
// import logo from './logo.svg';
import { useLocation } from "react-router-dom";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Accueil from './pages/accueil';
import Services from './pages/services';
import APropos from './pages/a_propos';
import Layout from './Layout';
import Projets from './pages/projets';
import Projet from './pages/projet';
import "@fontsource-variable/space-grotesk";
import Equipe from './pages/equipe';
import Contact from './pages/contact';
import AdminDashboard from './pages/admin/dashboard';
import Login from './pages/admin/login';
import AdminProjets from './pages/admin/projets';
import AdminModifierProjet from './pages/admin/ModifierProjet';
import AdminNouveauProjet from './pages/admin/nouveauProjet';
import { Toaster } from 'react-hot-toast';
import AdminEmployes from './pages/admin/employes';
import AdminModifierMembreEquipe from './pages/admin/ModifierMembreEquipe';
import AdminNouveauMembreEquipe from './pages/admin/NouveauMembreEquipe';
import './styles/global.css'
import NouvelleFullScreen from './pages/NouvelleFullScreen';
import Nouvelles from './pages/nouvelles';
import AdminNouvelles from './pages/admin/nouvelles';
import AdminModifierNouvelle from './pages/admin/ModifierNouvelle';

function App() {
  // https://v5.reactrouter.com/web/guides/scroll-restoration
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div>
      <Toaster />
      <BrowserRouter>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<Accueil />} />
            <Route path="/services" element={<Services />} />
            <Route path="/a_propos" element={<APropos />} />
            <Route path="/projets" element={<Projets />} />
            <Route path="/projets/:id" element={<Projet />} />
            <Route path="/equipe" element={<Equipe />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/nouvelles" element={<Nouvelles />} />
            <Route path="/nouvelles/:id" element={<NouvelleFullScreen />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/projets" element={<AdminProjets />} />
            <Route path="/admin/projets/:id" element={<AdminModifierProjet />} />
            <Route path="/admin/projets/nouveau_projet" element={<AdminNouveauProjet />} />
            <Route path="/admin/equipe/" element={<AdminEmployes />} />
            <Route path="/admin/equipe/:id" element={<AdminModifierMembreEquipe />} />
            <Route path="/admin/equipe/nouveau_membre" element={<AdminNouveauMembreEquipe />} />
            <Route path="/admin/nouvelles" element={<AdminNouvelles />} />
            <Route path="/admin/nouvelles/:id" element={<AdminModifierNouvelle />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;

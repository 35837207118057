import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  fr: {
    translation: {
      global: {
        chargement: "Chargement...",
        chargement_erreur: "Si le contenu n'apparait pas en moins de 5 à 10 secondes, rechargez la page.",
        voir_plus: "Voir plus",
        erreur: {
          erreur_bd: {
            titre: "Erreur de base de données..."
          }
        }
      },
      pages: {
        accueil: {
          titre: "Accueil",
          qui_sommes_nous: "Qui sommes-nous?",

          sous_titre_qui_sommes_nous: "Un entrepreneur général de Montréal réputé pour ses services de rénovation sur mesure et de qualité supérieure.",
          contenu_qui_sommes_nous_paragraphe_0: "Au service des Montréalais depuis plus de {{depuis}} ans, Construction Delamain s’est taillé une solide réputation grâce à son sens indéfectible du design, son savoir-faire exceptionnel et son approche personnalisée. En choisissant Construction Delamain, vous choisissez une équipe de professionnels de la rénovation qui saura porter une attention particulière à vos besoins et préférences.",
          contenu_qui_sommes_nous_paragraphe_1: "Nous nous engageons à fournir des résultats exceptionnels tout en respectant les délais et les budgets. Nous vous aiderons à mener votre projet de rénovation à bien, exactement comment vous l’aviez imaginé. Et s’il advenait que l’envergure du projet change ou que des imprévus surviennent en cours de route, nous avons l’expérience nécessaire pour proposer des solutions innovantes et abordables qui répondront à vos besoins.",

          specialites: "Spécialités",
          specialites_sous_titre_reno_residentielle: "Rénovation résidentielle",
          specialites_paragraphe_reno_residentielle_0: "Les projets résidentiels de grande envergure, ceux qui nécessitent une expertise particulière ou qui demandent plus de finesse que des rénovations habituelles, sont notre spécialité. Jetez un coup d’œil à notre portfolio résidentiel, vous y verrez nos inspirantes réalisations intérieures et extérieures et vous verrez comment nous bâtissons l’extraordinaire.",
          specialites_sous_titre_reno_commerciale: "Rénovation commerciale ",
          specialites_paragraphe_reno_commerciale_0: "Nos services de rénovation commerciale comprennent la conception/construction et les travaux généraux.",

          services: "Services",
          services_paragraphe_0: "Construction Delamain s’est forgé une solide réputation de chef de file en tant qu’entreprise de construction polyvalente. Nos projets vont de la rénovation d’édifices patrimoniaux aux lofts modernes et aux espaces commerciaux.",
          services_paragraphe_1: "Tous nos travaux sont assurés, garantis et couverts par la Régie du bâtiment du Québec ({{ license_rbq }}).",
          services_sous_titre_conception_construction: "Conception-construction",
          services_paragraphe_conception_construction_0: "Vous souhaitez un espace unique, aussi beau que fonctionnel et dans lequel vous vous sentirez merveilleusement bien pour plusieurs années? Nous pouvons vous aider. Notre sens inné de l’esthétisme est un atout considérable pour quiconque nous choisit comme partenaires pour un projet. Nous sommes dévoués à la création d’espaces visuellement agréables et intemporels.",
          services_paragraphe_conception_construction_1: "Notre processus de conception-construction vous guide tout au long des quatre grandes étapes du projet (consultation du designer, établissement du budget et conception, pré-construction, construction) afin d’assurer un résultat harmonieux et réussi.",
          services_sous_titre_travaux_generaux: "Travaux généraux",
          services_paragraphe_travaux_generaux_0: "Vous pouvez compter sur nos années d’expérience et sur notre réputation. Nous sommes reconnus pour notre sens aigu du design, notre capacité à résoudre les problèmes et pour notre engagement à tenir nos promesses. Notre souci du détail et la qualité constante de nos travaux sont la fondation même de notre solide réputation, tant auprès de nos clients résidentiels que de notre clientèle du secteur commercial.",
          services_sous_titre_peinture_interieure_et_exterieure: "Peinture intérieure et extérieure",
          services_paragraphe_peinture_interieure_et_exterieure_0: "Nos peintres en bâtiment sont triés sur le volet et capables d’exécuter pratiquement tous les travaux de peinture résidentiels ou commerciaux, peu importe l’envergure et la complexité. Notre équipe de professionnels possède l’expérience nécessaire pour opérer les nacelles à bras articulé et les pulvérisateurs de peinture, ce qui accroît grandement son efficacité.",
          services_paragraphe_peinture_interieure_et_exterieure_1: "Nous respectons les délais serrés, nos prix sont concurrentiels, nous sommes entièrement assurés et cautionnés et, si nécessaire, nous sommes prêts à travailler 24 heures sur 24 pour compléter votre projet dans les temps.",

          a_propos: "À Propos",
          a_propos_paragraphe_0: "En tant qu’entreprise de travaux généraux primée, nous comblons les besoins de clients des secteurs commercial et résidentiel de la région montréalaise depuis {{ annee_fondation }}, et 90 % de nos projets proviennent de recommandations ou de clients qui nous sont fidèles.",
          a_propos_paragraphe_1: "Nous sommes fiers de la réputation que nous avons acquise à titre d’entreprise de construction de confiance et respectée, qui construit à la fois des espaces d’exception et des relations durables avec ses clients. En choisissant Construction Delamain, vous profitez d’experts qui vous écouteront attentivement et qui travailleront en étroite collaboration avec vous tout au long du projet.",
          a_propos_sous_titre_notre_equipe: "Notre équipe",
          a_propos_paragraphe_notre_equipe_0: "Depuis {{ depuis }} ans maintenant, Antonie Bordeleau et Maxime Bouchard ont uni leurs talents en affaires en conception et en construction pour créer une entreprise de travaux généraux unique et respectée.",
          a_propos_paragraphe_notre_equipe_1: "Grâce à son optimisme et à son ouverture, Antonie transforme les obstacles en possibilités. Les clients apprécient sa nature calme, ses solutions créatives et son énergie inépuisable. De son côté, Maxime est méticuleux et reconnu pour son grand souci du détail et son sens inné du design. Très polyvalent, c’est un spécialiste du multitâches et il est passé maître dans la perfection de systèmes et la résolution de problèmes.",
          a_propos_paragraphe_notre_equipe_2: "La combinaison de leurs valeurs et de leurs talents respectifs est la pierre d’assise de la philosophie Delamain — bâtir un projet à la fois, avec intégrité, professionnalisme et transparence. Antonie et Maxime sont des passionnés, ils aiment leur profession et se font un point d’honneur d’assurer la réussite de chaque projet qu’ils entreprennent.",

          nouvelles: "Nouvelles",
        },
        contact: {
          titre: "Contact",
          horaire_ouverture: "Horaire d'ouverture",
          numero_telephone: "Numéro de téléphone",
          adresse: "Adresse",
          prenom: 'Prénom',
          nom_famille: 'Nom de famille',
          emplacement: 'Emplacement du projet (adresse, ville, etc.)',
          date_debut_projet: "Date souhaitée du début des travaux",
          description: 'Description du projet',
          documents: "Je détiens les plans d'architecte",
          permis: "Je détiens les permis municipaux requis",
          le_fichier_zip: "Si vous voulez, vous pouvez téléverser un document (plan, permis etc.) au format PDF, DOC etc., ou encore en regrouper plusieurs dans un fichier ZIP:",
          courriel: {
            adresse: "Adresse courriel",
            erreur: "Une erreur est survenue.",
            succes: "Courriel envoyé.",
            courriel_string: "Courriel",
            format_invalide: "Format d'adresse de courriel invalide",
          },
          bouton_choisir_fichier_a_televerser: "Sélectionnez un fichier",
          choix_de_fichier_a_televerser: "Fichier choisi:",
          champ_requis: "ce champ est obligatoire",
        },
        equipe_partenaire: {
          titre: "Nos partenaires",
        },
        equipe: {
          titre: "Notre équipe",
        },
        projets: {
          titre: "Réalisations",
          trier: "Trier les projets",
          defaut: "Par défaut",
          alphabetique: "Alphabétique",
          alphabetique_inverse: "Alphabétique inverse",
          date: "Date",
          date_inverse: "Date inverse",
          ancien: "Ancien",
          recent: "Récent",
          filtrer: "Filtrer les projets",
          tout: "Tout",
          residentiel: "Résidentiel",
          commercial: "Commercial",
          industriel: "Industriel",
          erreur: {
            aucun_projet_trouve: {
              titre: "Aucun projet n'a été trouvé avec les critères demandés"
            }
          }
        },
        projet: {
          erreur: {
            projet_introuvable: {
              titre: "Erreur: Projet introuvable.",
              description: "Le projet demandé n'a pas pu être trouvé."
            }
          },
          bouton_retour: "Retour aux Réalisations",
        },
        nouvelles: {
          titre: "Nouvelles",
        },
        nouvelle: {
          bouton_retour: "Retour aux Nouvelles",
        },
      },
      components: {
        headerbar: {
          titre: "DELAMAIN",
          sous_titre: "Entrepreneur Général",
          accueil: "Accueil",
          projets: "Réalisations",
          services: "Services",
          equipe: "Notre équipe",
          a_propos: "À propos",
          contact: "Contact",
          nouvelles: "Nouvelles",
        },
        footer: {
          titre: "CONSTRUCTION DELAMAIN",
          license_rbq: "{{ license_rbq }}",
          contact: "Contact",
          adresse: {
            rue: "3713 Boul. Saint-Laurent, bureau 203",
            region: "Montréal, Québec",
            code_postal: "H2X 2V7"
          },
          horaire_semaine: "lundi — vendredi: 9:00 – 17:00",
          horaire_fin_de_semaine: "samedi & dimanche: 11:00 – 15:00",
          numero_telephone: "(514) 437-0137",
          courriel: "info@delamain.ca",
        }
      }
    }
  },
  en: {
    translation: {
      global: {
        chargement: "Loading...",
        chargement_erreur: "If the content does not appear in less than 5 to 10 seconds, reload the page.",
        voir_plus: "More",
        erreur: {
          erreur_bd: {
            titre: "Datbase error..."
          }
        }
      },
      pages: {
        accueil: {
          titre: "Home",

          qui_sommes_nous: "Who are we?",
          sous_titre_qui_sommes_nous: "A renowned general contractor in Montréal known for its custom renovation services and superior quality.",
          contenu_qui_sommes_nous_paragraphe_0: "Serving Montrealers for over {{depuis}} years, Construction Delamain has built a strong reputation thanks to its unwavering sense of design, exceptional expertise, and personalized approach. By choosing Construction Delamain, you are choosing a team of renovation professionals who will pay special attention to your needs and preferences.",
          contenu_qui_sommes_nous_paragraphe_1: "We are committed to delivering exceptional results while respecting deadlines and budgets. We will help you bring your renovation project to life, just as you imagined it. And if the scope of the project changes or unforeseen events occur along the way, we have the necessary experience to offer innovative and affordable solutions that meet your needs.",

          specialites: "Specialties",
          specialites_sous_titre_reno_residentielle: "Residential Renovation",
          specialites_paragraphe_reno_residentielle_0: "Large-scale residential projects, those requiring special expertise, or demanding more finesse than usual renovations, are our specialty. Take a look at our residential portfolio, where you will see our inspiring interior and exterior achievements and how we build the extraordinary.",
          specialites_sous_titre_reno_commerciale: "Commercial Renovation",
          specialites_paragraphe_reno_commerciale_0: "Our commercial renovation services include design/construction and general works.",

          services: "Services",
          services_paragraphe_0: "Construction Delamain has built a strong reputation as a versatile construction company leader. Our projects range from renovating heritage buildings to modern lofts and commercial spaces.",
          services_paragraphe_1: "All our work is insured, guaranteed, and covered by the Régie du bâtiment du Québec ({{ license_rbq }}).",

          services_sous_titre_conception_construction: "Design-Construction",
          services_paragraphe_conception_construction_0: "Do you want a unique space, as beautiful as it is functional, and in which you will feel wonderfully well for several years? We can help you. Our innate sense of aesthetics is a considerable asset for anyone who chooses us as partners for a project. We are dedicated to creating visually pleasing and timeless spaces.",
          services_paragraphe_conception_construction_1: "Our design-construction process guides you through the four major stages of the project (designer consultation, budgeting and design, pre-construction, construction) to ensure a harmonious and successful outcome.",

          services_sous_titre_travaux_generaux: "General Works",
          services_paragraphe_travaux_generaux_0: "You can count on our years of experience and our reputation. We are known for our acute sense of design, our ability to solve problems, and our commitment to keeping our promises. Our attention to detail and the consistent quality of our work are the very foundation of our strong reputation, both with our residential clients and our commercial clientele.",
          services_sous_titre_peinture_interieure_et_exterieure: "Interior and Exterior Painting",
          services_paragraphe_peinture_interieure_et_exterieure_0: "Our building painters are carefully selected and capable of carrying out virtually all residential or commercial painting work, regardless of the scale and complexity. Our professional team has the necessary experience to operate articulated boom lifts and paint sprayers, greatly increasing its efficiency.",
          services_paragraphe_peinture_interieure_et_exterieure_1: "We meet tight deadlines, our prices are competitive, we are fully insured and bonded, and, if necessary, we are ready to work 24 hours a day to complete your project on time.",


          a_propos: "About Us",
          a_propos_paragraphe_0: "As an award-winning general works company, we have been meeting the needs of clients in the commercial and residential sectors of the Montréal region since {{ annee_fondation }}, and 90% of our projects come from recommendations or loyal clients.",
          a_propos_paragraphe_1: "We are proud of the reputation we have gained as a trusted and respected construction company, which builds both exceptional spaces and lasting relationships with its clients. By choosing Construction Delamain, you benefit from experts who will listen carefully to you and work closely with you throughout the project.",
          a_propos_sous_titre_notre_equipe: "Our Team",
          a_propos_paragraphe_notre_equipe_0: "Since {{ depuis }} years now, Antonie Bordeleau and Maxime Bouchard have combined their business, design, and construction talents to create a unique and respected general contracting company.",
          a_propos_paragraphe_notre_equipe_1: "Thanks to his optimism and openness, Antonie turns obstacles into possibilities. Clients appreciate his calm nature, creative solutions, and inexhaustible energy. On the other hand, Maxime is meticulous and recognized for his great attention to detail and innate sense of design. Highly versatile, he is a multitasking specialist and has mastered the perfection of systems and problem-solving.",
          a_propos_paragraphe_notre_equipe_2: "The combination of their values and their respective talents is the cornerstone of the Delamain philosophy — to build one project at a time, with integrity, professionalism, and transparency. Antonie and Maxime are passionate; they love their profession and make it a point of honor to ensure the success of each project they undertake.",

          nouvelles: "News"
        },
        contact: {
          titre: "Contact",
          horaire_ouverture: "Opening hours",
          numero_telephone: "Phone number",
          adresse: "Address",
          prenom: "First name",
          nom_famille: "Last name",
          emplacement: "Project location (address, city, etc.)",
          date_debut_projet: "Desired start date of the project",
          description: "Project description",
          documents: "I have architecture plans",
          permis: "I have the required municipal permits",
          le_fichier_zip: "If you wish, you can upload a document (plan, permit, etc.) in PDF, DOC, etc. format, or group several in a ZIP file:",
          courriel: {

            adresse: "Email address",
            erreur: "An error has occurred.",
            succes: "Email sent.",
            courriel_string: "Email",
            format_invalide: "Invalid email address format",

          },
          bouton_choisir_fichier_a_televerser: "Select a file",
          choix_de_fichier_a_televerser: "Chosen file:",
          champ_requis: "This field is required",
        },
        equipe_partenaire: {
          titre: "Our partners",
        },
        equipe: {
          titre: "Our team",
        },
        projets: {
          titre: "Projects",
          trier: "Sort projects",
          defaut: "Default",
          alphabetique: "Alphabetic",
          alphabetique_inverse: "Reverse alphabetic",
          date: "Date",
          date_inverse: "Reverse date",
          ancien: "Old",
          recent: "Recent",
          filtrer: "Filter projects",
          tout: "All",
          residentiel: "Residential",
          commercial: "Commercial",
          industriel: "Industrial",
          erreur: {
            aucun_projet_trouve: {
              titre: "No project was found with the requested criteria."
            }
          }
        },
        projet: {
          erreur: {
            projet_introuvable: {
              titre: "Error: Project not found.",
              description: "The requested project could not be found."
            }
          },
          bouton_retour: "Back to the Projects",
        },
        nouvelles: {
          titre: "News",
        },
        nouvelle: {
          bouton_retour: "Back to the News",
        },
      },
      components: {
        headerbar: {
          titre: "DELAMAIN",
          sous_titre: "General Contractor",
          accueil: "Home",
          projets: "Projects",
          services: "Services",
          equipe: "Our team",
          a_propos: "About us",
          contact: "Contact",
          nouvelles: "News",
        },
        footer: {
          titre: "CONSTRUCTION DELAMAIN",
          license_rbq: "{{ license_rbq }}",
          contact: "Contact",
          adresse: {
            rue: "3713 Boul. Saint-Laurent, bureau 203",
            region: "Montréal, Québec",
            code_postal: "H2X 2V7"
          },
          horaire_semaine: "Monday — Friday: 9:00 – 17:00",
          horaire_fin_de_semaine: "Saturday & Sunday: 11:00 – 15:00",
          numero_telephone: "(514) 437-0137",
          courriel: "info@delamain.ca"
        }
      }
    }
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      // Définit l'ordre de détection pour donner la priorité à la langue du navigateur de l'utilisateur
      // order: ['navigator'],
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    resources,
    fallbackLng: "fr",
  });

export default i18n;

import i18next from 'i18next';
import style from '../styles/selecteur_de_langue.module.css';

function SelecteurDeLangue() {
  // Fonction pour changer la langue
  const changeLangue = (lng: string) => {
    i18next.changeLanguage(lng);
  };

  const currentLang = i18next.language;

  return (
    <div className={style.language_container}>
      {currentLang !== 'fr' && (
        <div className={style.language_button} onClick={() => changeLangue('fr')}>
          FR
        </div>
      )}
      {currentLang !== 'en' && (
        <div className={style.language_button} onClick={() => changeLangue('en')}>
          EN
        </div>
      )}
    </div>
  );
}

export default SelecteurDeLangue;

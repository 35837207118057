import TitreCouleur from '../components/TitreCouleur';
import { useTranslation } from 'react-i18next';
import styles from '../styles/a_propos.module.css'
import * as var_glob from '../variables.mjs';

function APropos() {
  const { t } = useTranslation();

  return (
    <div>

      <div className={styles.contenu_flex}>

        <TitreCouleur texte={t('pages.accueil.a_propos')} taille={3.2}/>
        <div className={styles.categorie}>

            <p className={styles.content_gras}>
              {t('pages.accueil.a_propos_paragraphe_0',  { annee_fondation: var_glob._dateFondation.getFullYear()} )}
            </p>

            <p className={styles.content}>
              {t('pages.accueil.a_propos_paragraphe_1')}
            </p>


          <div className={styles.sous_categorie}>
            <h2 className={styles.sous_titre}>
              {t('pages.accueil.a_propos_sous_titre_notre_equipe')}
            </h2>
          </div>

            <p className={styles.content}>
              {t('pages.accueil.a_propos_paragraphe_notre_equipe_0',  { depuis: var_glob._anneesOperation })}
            </p>

            <p className={styles.content}>
              {t('pages.accueil.a_propos_paragraphe_notre_equipe_1')}
            </p>

            <p className={styles.content}>
              {t('pages.accueil.a_propos_paragraphe_notre_equipe_2')}
            </p>


        </div>

      </div>
      {/* <TitreCouleur texte={t('pages.accueil.titre')} taille={3.2} /> */}
    </div>
  );
}

export default APropos;

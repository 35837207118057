import style from '../styles/footer.module.css'
import { useTranslation } from 'react-i18next';
import * as var_glob from '../variables.mjs';

function Footer() {
    const { t } = useTranslation();

    return (
        <div className={style.container}>
            <h1 className={style.titre}>{t('components.footer.titre')}</h1>
            <h5 className={style.titre}>{t('components.footer.license_rbq', { license_rbq: var_glob._license_rbq })}</h5>

            {t('components.footer.horaire_semaine')} <br />
            <ul className={style.infos_contact}>
                <li id={style.telephone}>
                    <a href={`tel:${t('components.footer.numero_telephone')}`} className='global_regular_link_dark_bg num_de_telef'>
                        {t('components.footer.numero_telephone')}
                    </a>
                </li>

                <li id={style.email}>
                    <a href='mailto:info@delamain.ca' className='global_regular_link_dark_bg'>
                        {t('components.footer.courriel')}
                    </a>
                </li>

                <li id={style.adresse}>
                  <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      t('components.footer.adresse.rue') +
                        ' ' +
                      t('components.footer.adresse.region') +
                        ' ' +
                      t('components.footer.adresse.code_postal')
                    )}`} className="global_regular_link_dark_bg" >

                    {t('components.footer.adresse.rue')}
                    <br />
                    {t('components.footer.adresse.region')}
                    <br />
                    {t('components.footer.adresse.code_postal')}
                  </a>
                </li>
            </ul>
            <div className={style.copyright}>
              © { new Date().getFullYear() } delamain.ca
            </div>
        </div>
    );
}

export default Footer;

import style from '../styles/titre_couleur.module.css'

interface Props {
    texte: string
    taille: number
    position: string
}

const defaultProps: Props = {
    texte: '',
    taille: 1,
    position: "center",
}

function TitreCouleur(props: Props) {
    let marginRight;
    let marginLeft;

    switch (props.position) {
        case "left":
            // stylePosition = { marginRight: "auto" }
            marginLeft = 0;
            marginRight = "auto";
            break;
        case "center":
            marginLeft = "auto";
            marginRight = "auto";
            break;
        case "right":
            marginLeft = "auto";
            marginRight = 0;
            break;
    }

    return (
        <div className={style.container} style={{ marginRight: marginRight, marginLeft: marginLeft }}>
            <h1 style={{
                fontSize: `${props.taille}vmax`,
                marginRight: marginRight,
                marginLeft: marginLeft,
            }} className={`global_titre_couleur_gradient ${style.texte}`}>{props.texte}</h1>
        </div>
    );
}

TitreCouleur.defaultProps = defaultProps;

export default TitreCouleur;

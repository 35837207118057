import { useEffect, useState } from 'react';
import TitreCouleur from '../components/TitreCouleur';
import { useTranslation } from 'react-i18next';
import styles from '../styles/accueil.module.css'
import { Link } from 'react-router-dom';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { db } from '../database';
import EcranChargement from '../components/EcranChargement';
import * as var_glob from '../variables.mjs';

function Accueil() {
	const { t } = useTranslation();


	const [nouvelle, setNouvelle] = useState<any>();

  const [isNouvelles] = useState(false)
	const [values, loading] = useCollectionDataOnce(
		collection(db, 'nouvelles')
	);

	useEffect(() => {
		if (values) {
			console.log(values)
			let sortedValues = values?.sort((a: any, b: any) => b.date_publication.seconds - a.date_publication.seconds)
			setNouvelle(sortedValues[0])
		}
	}, [values])

	return (
		<div>
			<div className={styles.video_container}>
				<Link to={'/projets/DZYXin5kKeFj8smk26dS'}>
					<img src='overlay.svg' alt='Overlay de ville par dessus la video' className={styles.image_overlay_video} />
					<video
						id={styles.video}
						autoPlay
						playsInline
						muted
						loop
						poster='thumbnail.webp'>
						<source src='video_demo_immobilier.webm' type="video/webm" />
					</video>
				</Link>
			</div>

			<div className={styles.contenu_flex}>

				<TitreCouleur texte={t('pages.accueil.qui_sommes_nous')} taille={3.2} position='left' />
				<h2 className={styles.sous_titre}>
					{t('pages.accueil.sous_titre_qui_sommes_nous')}
				</h2>
				<div className={styles.categorie}>


					<div className={styles.sous_categorie}>
						<p className={styles.content}>
							{t('pages.accueil.contenu_qui_sommes_nous_paragraphe_0', { depuis: var_glob._anneesOperation })}
						</p>
					</div>

					<div className={styles.sous_categorie}>
						<p className={styles.content}>
							{t('pages.accueil.contenu_qui_sommes_nous_paragraphe_1')}
						</p>
					</div>

				</div>

				<TitreCouleur texte={t('pages.accueil.specialites')} taille={3.2} position='left' />
				<div className={styles.categorie}>


					<div className={styles.sous_categorie}>
						<h2 className={styles.sous_titre}>
							{t('pages.accueil.specialites_sous_titre_reno_residentielle')}
						</h2>
						<p className={styles.content}>
							{t('pages.accueil.specialites_paragraphe_reno_residentielle_0')}
						</p>
					</div>


					<div className={styles.sous_categorie}>
						<h2 className={styles.sous_titre}>
							{t('pages.accueil.specialites_sous_titre_reno_commerciale')}
						</h2>
						<p className={styles.content}>
							{t('pages.accueil.specialites_paragraphe_reno_commerciale_0')}
						</p>
					</div>


				</div>

        { isNouvelles && (
        <div>
            <TitreCouleur texte={t('pages.accueil.nouvelles')} taille={3.2} position='center' />
            {
              loading ? <EcranChargement /> : (
                <div>
                  {
                    !nouvelle ? "Aucun contenu" : (
                      <div style={{display:"flex", flexDirection:"column"}}>
                        <Link to={`/nouvelles/${nouvelle.id}`} style={{ textDecoration: 'none' }}>
                          <div className={styles.nouvelles_preview_container}>
                            <h2>{nouvelle.titre}</h2>
                            <p>{nouvelle.sous_titre}</p>
                          </div>
                        </Link>

                        <Link to={"/nouvelles"} style={{ margin: "0 auto", marginTop:"30px" }}>
                          <button className='global_button' >Voir plus</button>
                        </Link>
                      </div>

                    )
                  }
                </div>
              )
            }
          </div>
        )}
			</div>
		</div>
	);
}

export default Accueil;
